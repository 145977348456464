import { useEffect, useRef, useState } from "react";

import { isNonNullable } from "@/types/isNonNullable";
import { isNullable } from "@/types/isNullable";
import { useInterval } from "@/utils/useInterval";
import { useTimeout } from "@/utils/useTimeout";

interface UsePollingParams {
  callback: () => unknown;
  delay?: number;
  interval?: number;
}

interface UsePollingResponse {
  isPolling: boolean;
}

export const usePolling = (params: UsePollingParams): UsePollingResponse => {
  const { callback, delay, interval } = params;

  const [isPolling, setIsPolling] = useState(false);
  const [hasDelay, setHasDelay] = useState(false);
  const savedCallback = useRef<() => unknown>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useInterval(savedCallback.current, isPolling ? interval : undefined);

  useTimeout(
    () => {
      if (isNonNullable(savedCallback.current)) {
        savedCallback.current();
        setIsPolling(true);
      }
    },
    hasDelay ? delay : undefined,
  );

  useEffect(() => {
    if (isNullable(savedCallback.current)) {
      return;
    }

    if (isNonNullable(delay)) {
      setHasDelay(true);

      return;
    }

    if (isNonNullable(interval)) {
      savedCallback.current();
      setIsPolling(true);

      return;
    }

    setIsPolling(false);
  }, [delay, interval]);

  useEffect(() => {
    return () => {
      setIsPolling(false);
    };
  }, []);

  return { isPolling };
};
