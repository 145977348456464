import { useCallback, useEffect } from "react";
import type { CommentsPlaceholder } from "@motain/xpa-proto-files-web/lib/types/comments_placeholder";

import { OpenWebEvent } from "../types/analytics-openweb";
import {
  commentReported,
  conversationViewed,
  downVoteReactionButtonClicked,
  sendButtonClicked,
  shareOptionClicked,
  upVoteReactionButtonClicked,
} from "../utils/analytics";

import type { ShareType } from "../types/analytics-openweb";

export const useTrackingAnalyticsEvents = ({
  commentSectionId,
}: CommentsPlaceholder): void => {
  const shareOptionClickedHandler = useCallback(
    (event: ShareType) => {
      shareOptionClicked(event, commentSectionId);
    },
    [commentSectionId],
  );

  useEffect(() => {
    document.addEventListener(
      OpenWebEvent.conversationViewed,
      conversationViewed,
    );

    document.addEventListener(
      OpenWebEvent.sentMessageClicked,
      sendButtonClicked,
    );

    document.addEventListener(
      OpenWebEvent.shareType,
      shareOptionClickedHandler,
    );

    document.addEventListener(OpenWebEvent.commentReported, commentReported);

    document.addEventListener(
      OpenWebEvent.upVoteClick,
      upVoteReactionButtonClicked,
    );

    document.addEventListener(
      OpenWebEvent.downVoteClick,
      downVoteReactionButtonClicked,
    );

    return () => {
      document.removeEventListener(
        OpenWebEvent.conversationViewed,
        conversationViewed,
      );

      document.removeEventListener(
        OpenWebEvent.sentMessageClicked,
        sendButtonClicked,
      );

      document.removeEventListener(
        OpenWebEvent.shareType,
        shareOptionClickedHandler,
      );

      document.removeEventListener(
        OpenWebEvent.commentReported,
        commentReported,
      );

      document.removeEventListener(
        OpenWebEvent.upVoteClick,
        upVoteReactionButtonClicked,
      );

      document.removeEventListener(
        OpenWebEvent.downVoteClick,
        downVoteReactionButtonClicked,
      );
    };
  }, [shareOptionClickedHandler]);
};
