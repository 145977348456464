import type { Layout } from "@motain/xpa-proto-files-web/lib/types/xpa";
import { getLiveIndicatorFromLayout } from "@/components/news-page/get-live-indicator-from-layout";
import { isNullable } from "@/types/isNullable";

interface Response {
  delay?: number;
  interval?: number;
}

const STOPPED_POLLING_CONFIG: Response = {
  delay: undefined,
  interval: undefined,
};
const LIVE_BLOG_POLLING_CONFIG: Response = {
  delay: 4_000,
  interval: 4_000,
};

export const getPollingConfig = (layout: Layout): Response => {
  const liveIndicator = getLiveIndicatorFromLayout(layout);

  if (isNullable(liveIndicator)) {
    return STOPPED_POLLING_CONFIG;
  }

  return LIVE_BLOG_POLLING_CONFIG;
};
