import { FETCH_METHOD } from "@/constants/fetch-method";
import { getAccessTokenFromCookie } from "../utils/get-access-token-from-cookie";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { decodeSuperstructResult } from "@/utils/decodeSuperstructResult";
import type { CommentsSSOResponse } from "../CommentsOpenWeb.structs";
import { CommentsSSOResponseStruct } from "../CommentsOpenWeb.structs";
import { remoteLogger } from "@/utils/remoteLogger";
import type { HandShakeSSOProps } from "../types/auth-openweb";
import { useCallback } from "react";

const SSO_AUTH_IN_COMMENTS_ENDPOINT = `${envBasedConfig.webExperience}/users-auth-api/v1/comments/sso`;

export const useCommentsAuth = (): HandShakeSSOProps => {
  const accessToken = getAccessTokenFromCookie();

  // This function is used to authenticate the user with the comments service (OpenWeb) and our backend
  // It returns a function that takes a codeA and returns a codeB
  const handleHandShake = useCallback(
    async (codeA: string): Promise<string> => {
      const options: RequestInit = {
        method: FETCH_METHOD.POST,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          codeA,
        }),
        credentials: "include",
      };

      try {
        const response = await fetch(SSO_AUTH_IN_COMMENTS_ENDPOINT, options);
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }

        const responseData: unknown = await response.json();
        const decodedResponse = decodeSuperstructResult<CommentsSSOResponse>(
          responseData,
          CommentsSSOResponseStruct,
        );

        if ("value" in decodedResponse) {
          const { codeB } = decodedResponse.value;
          return codeB;
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (error) {
        remoteLogger.error("Error fetching get", error);
        throw error;
      }
    },
    [accessToken],
  );

  return handleHandShake;
};
