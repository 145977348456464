// See: https://developers.openweb.com/docs/event-listener-reference
export const OpenWebEvent = {
  conversationViewed: "spot-im-conversation-viewed",
  sentMessageClicked: "spot-im-current-user-sent-message-clicked",
  shareType: "spot-im-share-type",
  commentReported: "spot-im-comment-reported",
  upVoteClick: "spot-im-user-up-vote-click",
  downVoteClick: "spot-im-user-down-vote-click",
} as const;

interface Meta {
  spotId: string;
  postId: string;
  module: string;
  host?: HTMLElement;
}
type MessageType = "comment" | "reply";

export interface ConversationViewed extends Event {
  meta: Meta;
}

export interface SentMessageClicked extends Event {
  detail: {
    message_type: MessageType;
    parent_id?: string;
    labels: [string];
  };
  meta: Meta;
}

export interface ShareType extends Event {
  type: "facebook" | "link" | "twitter";
  message_type: MessageType;
  message_id: string;
  parent_id: string;
}

export interface CommentReported extends Event {
  detail: {
    type: MessageType;
    message_id: string;
    parent_id: string;
    reason: string;
  };
  meta: Meta;
}

export interface UpVoteClick extends Event {
  detail: {
    type: MessageType;
    message_id: string;
    parent_id: string;
    count: string;
  };
  meta: Meta;
}

export interface DownVoteClick extends Event {
  detail: {
    type: MessageType;
    message_id: string;
    parent_id: string;
    count: string;
  };
  meta: Meta;
}

declare global {
  interface DocumentEventMap {
    [OpenWebEvent.conversationViewed]: ConversationViewed;
    [OpenWebEvent.sentMessageClicked]: SentMessageClicked;
    [OpenWebEvent.shareType]: ShareType;
    [OpenWebEvent.commentReported]: CommentReported;
    [OpenWebEvent.upVoteClick]: UpVoteClick;
    [OpenWebEvent.downVoteClick]: DownVoteClick;
  }
}
