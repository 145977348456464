import { useEffect, type FC } from "react";
import { type TaboolaPlaceholder } from "@motain/xpa-proto-files-web/lib/types/taboola_placeholder";
import Head from "next/head";

import { FEED_PLACEMENT } from "./XpaTaboolaPlaceholder.constants";

import styles from "./XpaTaboolaPlaceholder.module.scss";
import { useStableSchema } from "@/utils/hooks/useStableSchema";
import { isNonNullable } from "@/types/isNonNullable";

interface TaboolaPlacementCommand {
  mode: string;
  container: string;
  placement: string;
  target_type: string;
}
interface TaboolaFlushCommand {
  flush: boolean;
}

type TaboolaCommand = TaboolaPlacementCommand | TaboolaFlushCommand;

declare global {
  interface Window {
    _taboola?: Array<TaboolaCommand>;
  }
}

export const XpaTaboolaPlaceholder: FC<TaboolaPlaceholder> = (props) => {
  const { divId, publisherId } = props;
  const schema = useStableSchema();

  useEffect(() => {
    if (isNonNullable(schema)) {
      window._taboola = window._taboola ?? [];

      window._taboola.push({
        mode: "alternating-thumbnails-a",
        container: `${divId}`,
        placement:
          schema === "dark" ? FEED_PLACEMENT.DARK : FEED_PLACEMENT.LIGHT,
        target_type: "mix",
      });

      window._taboola.push({
        flush: true,
      });
    }

    // Cleanup function to remove Taboola elements when the component is unmounted
    return () => {
      const taboolaElement = document.getElementById(`${divId}`);
      if (taboolaElement) {
        taboolaElement.innerHTML = "";
      }
    };
  }, [divId, schema]);

  return (
    <section className={styles.container}>
      <Head>
        <script
          type="text/javascript"
          async
          src="https://cdn.taboola.com/libtrc/unip/2177556/tfa.js"
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              window._taboola = window._taboola || [];
              _taboola.push({ article: 'auto' });
              !function (e, f, u, i) {
                if (!document.getElementById(i)) {
                  e.async = 1;
                  e.src = u;
                  e.id = i;
                  f.parentNode.insertBefore(e, f);
                }
              }(document.createElement('script'),
              document.getElementsByTagName('script')[0],
              '//cdn.taboola.com/libtrc/${publisherId}/loader.js',
              'tb_loader_script');
              if (window.performance && typeof window.performance.mark === 'function') {
                window.performance.mark('tbl_ic');
              }
            `,
          }}
        ></script>
      </Head>
      <div id={divId} />
    </section>
  );
};
