import type { FC } from "react";
import type { DividerWithTimestampComponent } from "@motain/xpa-proto-files-web/lib/types/news";
import styles from "./DividerWithTimestamp.module.scss";
import { useRouter } from "next/router";

export const DividerWithTimestamp: FC<
  NonNullable<DividerWithTimestampComponent>
> = (data) => {
  const { locale } = useRouter();
  const timeTitleFormatted = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(data.timestamp));
  return (
    <>
      {data.timestamp && (
        <>
          <hr className={styles.separator} />
          <time
            className={styles.timestamp}
            dateTime={data.timestamp}
            title={timeTitleFormatted}
          >
            <span className={styles.timeAgo}>{data.timeAgo}</span>
            <span className={styles.time}>{data.timeFormatted}</span>
          </time>
        </>
      )}
    </>
  );
};
