import { useCallback, useEffect, useReducer } from "react";

import type { Layout } from "@motain/xpa-proto-files-web/lib/types/xpa";
import { FETCH_METHOD } from "@/constants/fetch-method";
import { error as logError } from "@/utils/logger";
import { webExperienceFetch } from "@/utils/webExperienceFetch";

import { getPollingConfig } from "./get-polling-config";

import { lazyImportLayoutDecoder } from "@/utils/lazy-import-layout-decoder";
import { usePolling } from "@/utils/hooks/usePolling";

interface Polling {
  interval?: number;
  delay?: number;
}
interface UseNewsPageParams {
  initialLayout: Layout;
  path: string;
}
interface UseNewsPageResponse {
  layout: Layout;
}

interface UseNewsPageState {
  layout: Layout;
  polling: Polling;
}

const getState = (layout: Layout): UseNewsPageState => ({
  layout,
  polling: getPollingConfig(layout),
});

export const useNewsPage = (params: UseNewsPageParams): UseNewsPageResponse => {
  const { initialLayout, path } = params;

  const [{ layout, polling }, updateLayout] = useReducer(
    (_: UseNewsPageState, newLayout: Layout): UseNewsPageState =>
      getState(newLayout),
    getState(initialLayout),
  );

  const callback = useCallback(async () => {
    const errorMessage = `Failed to fetch ${path} in the polling process`;

    const decoder = await lazyImportLayoutDecoder();

    webExperienceFetch<Layout>(path, {
      method: FETCH_METHOD.GET,
      decoder,
    })
      .then((response) => {
        if (response.status !== 200 || !response.decoded) {
          logError(errorMessage);
          return;
        }
        updateLayout(response.decoded);
      })
      .catch((error) => {
        logError(errorMessage, error);
      });
  }, [path]);

  useEffect(() => {
    updateLayout(initialLayout);
  }, [initialLayout]);

  usePolling({
    callback,
    interval: polling.interval,
    delay: polling.delay,
  });

  return {
    layout,
  };
};
