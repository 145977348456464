import React from "react";
import type { FC } from "react";
import type { RelatedVideosComponent } from "@motain/xpa-proto-files-web/lib/types/related_videos";
import styles from "./RelatedVideos.module.scss";
import { VideoTeaser } from "@/components/video-teaser/VideoTeaser";

export const RelatedVideos: FC<NonNullable<RelatedVideosComponent>> = (
  data,
) => {
  return (
    <section className={styles.relatedVideos}>
      <header className={styles.relatedVideos__header}>{data.title}</header>
      <ul className={styles.relatedVideos__grid}>
        {data.items.map((item) => (
          <li key={item.uiKey} className={styles.relatedVideos__gridItem}>
            <VideoTeaser {...item} />
          </li>
        ))}
      </ul>
    </section>
  );
};
