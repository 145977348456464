import { remoteLogger } from "@/utils/remoteLogger";
import { FETCH_METHOD } from "@/constants/fetch-method";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { decodeSuperstructResult } from "@/utils/decodeSuperstructResult";
import { CommentsConsentResponseStruct } from "../CommentsOpenWeb.structs";
import type {
  CommentsConsent,
  CommentsConsentGranted,
} from "../types/comments-consent";
import { useEffect, useState } from "react";
import { isNonNullable } from "@/types/isNonNullable";

const USER_COMMENTS_CONSENT = `${envBasedConfig.webExperience}/users-auth-api/v1/consent`;
const UPDATE_USER_COMMENTS_CONSENT = `${envBasedConfig.webExperience}/users-auth-api/v1/consent/comments`;

export const useCommentsConsent = (
  userId?: string | undefined,
): CommentsConsent => {
  const [hasCommentsConsent, setCommentsConsent] = useState<boolean>(false);

  const fetchUserCommentsConsent = async (): Promise<boolean> => {
    try {
      const response = await fetch(USER_COMMENTS_CONSENT, {
        method: FETCH_METHOD.GET,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const responseData: unknown = await response.json();

      const decodedResponse = decodeSuperstructResult(
        responseData,
        CommentsConsentResponseStruct,
      );

      if ("value" in decodedResponse) {
        const { comments_consent } = decodedResponse.value;
        return comments_consent;
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      return false;
    }
  };

  const updateUserCommentsConsent = async () => {
    const consentGranted: CommentsConsentGranted = true;

    try {
      const response = await fetch(UPDATE_USER_COMMENTS_CONSENT, {
        method: FETCH_METHOD.PATCH,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          consent: consentGranted,
        }),
      });

      if (response.ok) {
        setCommentsConsent(consentGranted);
      }

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
    } catch (error) {
      remoteLogger.error("Error update comments consent", error);
    }
  };

  useEffect(() => {
    if (isNonNullable(userId)) {
      fetchUserCommentsConsent()
        .then((consent) => {
          setCommentsConsent(consent);
        })
        .catch((error) => {
          remoteLogger.error("Error fetching comments consent", error);
        });
    }
  }, [userId]);

  return {
    hasCommentsConsent,
    fetchUserCommentsConsent,
    updateUserCommentsConsent,
  };
};
