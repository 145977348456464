import type { Layout } from "@motain/xpa-proto-files-web/lib/types/xpa";
import type { LiveArticleIndicator } from "@motain/xpa-proto-files-web/lib/types/news";

export const getLiveIndicatorFromLayout = (
  layout: Layout,
): LiveArticleIndicator | undefined => {
  const { containers } = layout;

  const components = containers.flatMap((container) => {
    switch (container.type?.$case) {
      case "fullWidth":
        return container.type.fullWidth.component;
      case "grid":
        return container.type.grid.items.flatMap((item) => item.components);
      default:
        return [];
    }
  });

  const articleHeroBanner = components.find(
    (element) => element?.contentType?.$case === "articleHeroBanner",
  );

  if (articleHeroBanner?.contentType?.$case === "articleHeroBanner") {
    return articleHeroBanner.contentType.articleHeroBanner.liveArticleIndicator;
  }

  return undefined;
};
