import type { FC } from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { usePathname } from "next/navigation";
import { Conversation, OpenWebProvider } from "@open-web/react-sdk";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { useAuthentication } from "@/context/Authentication";
import { isNonNullable } from "@/types/isNonNullable";
import { useStableSchema } from "@/utils/hooks/useStableSchema";
import { useCommentsAuth } from "./hooks/useCommentsAuth";
import { useTrackingAnalyticsEvents } from "./hooks/useTrackingAnalyticsEvents";
import { useCommentsConsent } from "./hooks/useCommentsConsent";
import { useLockBodyScroll } from "@/utils/hooks/useLockBodyScroll";
import { remoteLogger } from "@/utils/remoteLogger";
import { PROFILE_SIGN_IN_ROUTE } from "@/constants/routes.constant";
import { SearchParam } from "@/enums/search-param.enum";
import router from "next/router";
import styles from "./CommentsOpenWeb.module.scss";
import type { CommentsPlaceholder } from "@motain/xpa-proto-files-web/lib/types/comments_placeholder";
import useTranslation from "next-translate/useTranslation";
import { Button } from "@/components/button/Button";
import { ButtonLink } from "@/components/button/ButtonLink";

import XIcon from "@/public/next-assets/close.svg";
import classnames from "classnames";

const OPEN_WEB_COMMENTS_SPOT_ID = envBasedConfig.openwebSpotId;

const redirectComments = () => {
  const urlSearchParams = new URLSearchParams({
    [SearchParam.Redirect]: router.asPath,
    [SearchParam.Origin]: "comments",
  });

  return router.locale === router.defaultLocale
    ? `/${
        router.defaultLocale ?? "en"
      }${PROFILE_SIGN_IN_ROUTE}?${urlSearchParams.toString()}`
    : `${PROFILE_SIGN_IN_ROUTE}?${urlSearchParams.toString()}`;
};

export const CommentsOpenWeb: FC<CommentsPlaceholder> = (props) => {
  const { commentSectionId, trackingEvents } = props;
  const { t } = useTranslation("web-payments");
  const [dialogIsOpen, setDialogOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const ref = useRef<HTMLDialogElement>(null);

  const pathname = usePathname();
  const schema = useStableSchema();
  const { authState } = useAuthentication();
  const userId =
    authState.kind === "authenticated" ? authState.user.id : undefined;

  const { fetchUserCommentsConsent, updateUserCommentsConsent } =
    useCommentsConsent(userId);

  const [hasCommentsConsent, setHasCommentsConsent] = useState(false);

  useTrackingAnalyticsEvents({ commentSectionId, trackingEvents });

  useEffect(() => {
    fetchUserCommentsConsent()
      .then((response) => {
        setHasCommentsConsent(response);
      })
      .catch((error) => {
        remoteLogger.error("Error update comments consent", error);
      });
  }, [fetchUserCommentsConsent]);

  const handleHandShake = useCommentsAuth();

  const authentication =
    isNonNullable(userId) && hasCommentsConsent
      ? { userId, performBEDHandshakeCallback: handleHandShake }
      : {};

  const handleStartSignIn = useCallback(() => {
    const redirectUrl = redirectComments();
    if (isNonNullable(userId) && !hasCommentsConsent) {
      setDialogOpen(true);
      return;
    }

    router
      .push(redirectUrl)
      .catch((error) => remoteLogger.error("Error redirect to sign in", error));
  }, [userId, hasCommentsConsent]);

  useEffect(() => {
    const handleLoginStart = (event: Event) => {
      event.preventDefault();
      handleStartSignIn();
    };

    document.addEventListener("spot-im-login-start", handleLoginStart);

    return () => {
      document.removeEventListener("spot-im-login-start", handleLoginStart);
    };
  }, [handleStartSignIn]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (dialogIsOpen) {
      ref.current.showModal();
    } else {
      ref.current.close();
    }
  }, [dialogIsOpen]);

  const handleClick = () => {
    setLoading(true);

    updateUserCommentsConsent()
      .then(() => {
        setDialogOpen(false);
        setHasCommentsConsent(true);
        setLoading(false);
      })
      .catch((error) => {
        remoteLogger.error("Error update comments consent", error);
        setLoading(false);
      });

    return;
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  useLockBodyScroll(dialogIsOpen);

  return (
    <>
      <div className={styles.adWrapper}>
        <div data-openweb-ad data-row="1" data-column="1"></div>
      </div>
      <OpenWebProvider
        spotId={OPEN_WEB_COMMENTS_SPOT_ID}
        authentication={authentication}
      >
        <Conversation
          postId={commentSectionId}
          theme={schema ?? "light"}
          data-testid="comments-openweb"
          postUrl={pathname}
          className={styles.container}
        />
      </OpenWebProvider>
      {dialogIsOpen && (
        <dialog
          ref={ref}
          onClose={handleClose}
          className={styles.dialogWrapper}
        >
          <div className={classnames("title-5-bold", styles.header)}>
            <p>{t`COMMENT_CONSENT_TITLE`}</p>
            <button
              className={styles.xIcon}
              aria-label={"Modal X Mark"}
              onClick={handleClose}
            >
              <XIcon />
            </button>
          </div>
          <p className={styles.infoText}>{t`COMMENT_CONSENT_BODY`}</p>
          <div className={styles.footer}>
            <Button variant="fill" onClick={handleClick} disabled={isLoading}>
              {t`COMMENT_CONSENT_BUTTON_ACCEPT`}
            </Button>
            <ButtonLink
              variant="outline"
              href={t`ONEFOOTBALL_PRIVACY_POLICY_URL`}
              target="_blank"
            >
              {t`COMMENT_CONSENT_BUTTON_INFO`}
            </ButtonLink>
          </div>
        </dialog>
      )}
    </>
  );
};
