import { validate } from "superstruct";
import type { Struct } from "superstruct";
import type { Result } from "./result";
import { err, ok } from "./result";

// maps superstruct's decoding result to our Result type
export function decodeSuperstructResult<T>(
  value: unknown,
  struct: Struct<T>,
): Result<T> {
  const result = validate(value, struct);

  if (result[0] === undefined) {
    return ok(result[1]);
  }

  return err(result[0]);
}
