import { boolean, object, string } from "superstruct";
import type { Infer } from "superstruct";

export const CommentsSSOResponseStruct = object({
  codeB: string(),
});

export type CommentsSSOResponse = Infer<typeof CommentsSSOResponseStruct>;

export const CommentsConsentResponseStruct = object({
  consent_seen: boolean(),
  subscribed: boolean(),
  comments_consent: boolean(),
});

export type CommentsConsentResponse = Infer<
  typeof CommentsConsentResponseStruct
>;
