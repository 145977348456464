import type { FC } from "react";
import type { EntityChip as XpaEntityChip } from "@motain/xpa-proto-files-web/lib/types/entity_chips_list";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import styles from "./EntityChip.module.scss";
import classNames from "classnames";
import { isNonNullable } from "@/types/isNonNullable";
import { Title } from "@/xpa-components/title/Title";
import Link from "next/link";
import { isNullable } from "@/types/isNullable";

type EntityChipProps = XpaEntityChip & {
  isLarge?: boolean;
};

export const EntityChip: FC<EntityChipProps> = (props) => {
  const { link, title, isLarge = false, logo } = props;

  if (isNullable(link)) {
    return null;
  }

  return (
    <Link
      className={classNames(styles.container, isLarge && styles.containerLarge)}
      href={link.urlPath}
      aria-label={title?.text}
      onClick={() => {
        if (isNonNullable(link)) {
          trackingService.sendXpaTracking(
            link.trackingEvents,
            EventType.EVENT_CLICK,
          );
        }
      }}
    >
      <div className={isLarge ? styles.contentLarge : styles.content}>
        {logo && (
          <div className={isLarge ? styles.logoLarge : styles.logo}>
            <ImageWithFallback
              width={isLarge ? 40 : 20}
              height={isLarge ? 40 : 20}
              alt={logo.alt}
              src={logo.path}
              darkModeSrc={logo.darkModePath}
            />
          </div>
        )}

        {isNonNullable(title) && (
          <div className={styles.titles}>
            {isLarge ? (
              <Title className={styles.titleLarge} {...title} />
            ) : (
              <Title className={styles.title} {...title} />
            )}
          </div>
        )}
      </div>
    </Link>
  );
};
