import type { FC } from "react";
import styles from "./EntityChipList.module.scss";
import type { EntityChipList as XpaEntityChipList } from "@motain/xpa-proto-files-web/lib/runtime/entity_chips_list";
import { isNonNullable } from "@/types/isNonNullable";
import { Title } from "@/xpa-components/title/Title";
import { EntityChip } from "@/xpa-components/entity-chip/EntityChip";

export const EntityChipList: FC<NonNullable<XpaEntityChipList>> = (props) => {
  const { title, chips } = props;
  return (
    <article className={styles.container}>
      {isNonNullable(title) && (
        <header className={styles.title}>
          <Title {...title} />
        </header>
      )}
      <ul className={styles.list}>
        {isNonNullable(chips) &&
          chips.map((chip) => {
            return (
              <li key={chip.uiKey}>
                <EntityChip {...chip} />
              </li>
            );
          })}
      </ul>
    </article>
  );
};
