import type { Layout } from "@motain/xpa-proto-files-web/lib/runtime/xpa";
import { ClientParameterType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import type { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { fetchXpaLayoutWithResponseInfo } from "@/utils/fetchXpaLayout";
import { type FC, useEffect } from "react";
import type { XpaSupportedComponentsResolverProps } from "@/components/xpa-layout/XpaLayout";
import { XpaLayout } from "@/components/xpa-layout/XpaLayout";
import { Title } from "@/xpa-components/title/Title";
import { HorizontalSeparator } from "@/xpa-components/horizontal-separator/HorizontalSeparator";
import { ArticleParagraph } from "@/xpa-components/article-paragraph/ArticleParagraph";
import { ArticleList } from "@/xpa-components/article-list/ArticleList";
import { ArticleBlockquote } from "@/xpa-components/article-blockquote/ArticleBlockquote";
import { XpaGoogleAdsPlaceholder } from "@/modules/ads";
import { ArticleHeroBanner } from "@/xpa-components/article-hero-banner/ArticleHeroBanner";
import { PublisherImprintLink } from "@/xpa-components/publisher-imprint-link/PublisherImprintLink";
import { NativeShareComponent } from "@/xpa-components/native-share/NativeShare";
import { ImageWrapper } from "@/xpa-components/image-wrapper/ImageWrapper";
import { RelatedNews } from "@/xpa-components/related-news/RelatedNews";
import { RelatedVideos } from "@/xpa-components/related-videos/RelatedVideos";
import { MatchCardComponent } from "@/xpa-components/match-card/MatchCard";
import { Gallery } from "@/xpa-components/gallery/Gallery";
import { VisibilityTracker } from "@/xpa-components/visibility-tracker/VisibilityTracker";
import { ArticleTwitter } from "@/xpa-components/twitter/ArticleTwitter";
import { YoutubeVideo } from "@/xpa-components/youtube-video/YoutubeVideo";
import styles from "@/styles/pages/News.module.scss";
import { UpcomingMatches } from "@/xpa-components/upcoming-matches/UpcomingMatches";
import { DefaultVideojsWrapper } from "@/xpa-components/videojs-wrapper/DefaultVideojsWrapper";
import { EmbeddedVideoPlayer } from "@/xpa-components/embedded-video-player/EmbeddedVideoPlayer";
import {
  clientSideParamsService,
  StreamName,
  useRegisterStream,
  useRegisterXpaStream,
} from "@/modules/analytics";
import { VisibilityAlert } from "@/components/visibility-alert/VisibilityAlert";
import { EntityChipList } from "@/xpa-components/entity-chip-list/EntityChipList";
import { EntityNavigationComponent } from "@/xpa-components/entity-navigation/EntityNavigation";
import { useRouter } from "next/router";
import { useNewsPage } from "@/components/news-page/useNewsPage";
import { XpaTaboolaPlaceholder } from "@/xpa-components/taboola/XpaTaboolaPlaceholder";
import { DividerWithTimestamp } from "@/xpa-components/divider-with-timestamp/DividerWithTimestamp";
import { CommentsOpenWeb } from "@/xpa-components/comments-openweb/CommentsOpenWeb";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { YahooBanner } from "@/xpa-components/yahoo-banner/YahooBanner";
const News: FC<InferGetServerSidePropsType<typeof getServerSideProps>> = (
  props,
) => {
  const { asPath, locale = "en" } = useRouter();
  const { layout } = useNewsPage({
    initialLayout: props,
    path: `/${locale}${asPath}`,
  });

  useRegisterStream(StreamName.NewsDetails);
  useRegisterXpaStream(props);

  useEffect(() => {
    clientSideParamsService.clear();
  }, []);

  return (
    <div className={styles.xpaContainer}>
      <XpaLayout
        layout={layout}
        XpaSupportedComponentsResolver={XpaSupportedComponentsResolver}
      />
      <VisibilityAlert
        onVisible={() => {
          clientSideParamsService.set(
            ClientParameterType.PARAMETER_SCROLL_TO_BOTTOM,
            true,
          );
        }}
      />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<Layout> = async (ctx) => {
  const layoutResponse = await fetchXpaLayoutWithResponseInfo(ctx);

  if (layoutResponse.status === 404) {
    return { notFound: true };
  } else {
    return { props: layoutResponse.layout };
  }
};

export default News;

const XpaSupportedComponentsResolver: XpaSupportedComponentsResolverProps = ({
  contentType,
  uiKey,
}) => {
  switch (contentType.$case) {
    case "articleBlockquote":
      return <ArticleBlockquote {...contentType.articleBlockquote} />;
    case "articleHeroBanner":
      return <ArticleHeroBanner {...contentType.articleHeroBanner} />;
    case "articleList":
      return <ArticleList {...contentType.articleList} />;
    case "articleParagraph":
      return <ArticleParagraph {...contentType.articleParagraph} />;
    case "articleTwitter":
      return <ArticleTwitter {...contentType.articleTwitter} />;
    case "dividerWithTimestamp":
      return <DividerWithTimestamp {...contentType.dividerWithTimestamp} />;
    case "embeddedVideoPlayer":
      return <EmbeddedVideoPlayer {...contentType.embeddedVideoPlayer} />;
    case "entityChipList":
      return <EntityChipList {...contentType.entityChipList} />;
    case "entityNavigation":
      return <EntityNavigationComponent {...contentType.entityNavigation} />;
    case "gallery":
      return <Gallery {...contentType.gallery} />;
    case "googleAdsPlaceholder":
      return <XpaGoogleAdsPlaceholder {...contentType.googleAdsPlaceholder} />;
    case "yahooBanner":
      if (envBasedConfig.featureFlags.yahoo) {
        return <YahooBanner />;
      }
      return null;
    case "horizontalSeparator":
      return <HorizontalSeparator {...contentType.horizontalSeparator} />;
    case "image":
      return <ImageWrapper {...contentType.image} />;
    case "matchCard":
      return (
        <div className={styles.matchCardComponentWrapper}>
          <MatchCardComponent {...contentType.matchCard} />
        </div>
      );
    case "nativeShare":
      return <NativeShareComponent {...contentType.nativeShare} />;
    case "publisherImprintLink":
      return <PublisherImprintLink {...contentType.publisherImprintLink} />;
    case "relatedNews":
      return <RelatedNews {...contentType.relatedNews} />;
    case "relatedVideos":
      return <RelatedVideos {...contentType.relatedVideos} />;
    case "commentsPlaceholder":
      return <CommentsOpenWeb {...contentType.commentsPlaceholder} />;
    case "taboolaPlaceholder":
      if (envBasedConfig.featureFlags.taboola) {
        return <XpaTaboolaPlaceholder {...contentType.taboolaPlaceholder} />;
      }
      return null;
    case "title":
      return <Title {...contentType.title} />;
    case "upcomingMatches":
      return <UpcomingMatches {...contentType.upcomingMatches} />;
    case "videojsPlayer":
      return (
        <DefaultVideojsWrapper
          videoJsOptions={contentType.videojsPlayer}
          id={uiKey}
          shouldReInit={false}
        />
      );
    case "visibilityTracker":
      return (
        <>
          <VisibilityTracker {...contentType.visibilityTracker} />
          <VisibilityAlert
            onVisible={() => {
              clientSideParamsService.set(
                ClientParameterType.PARAMETER_SCROLL_TO_END_OF_SECTION,
                true,
              );
            }}
          />
        </>
      );
    case "youtube":
      return <YoutubeVideo {...contentType.youtube} />;
    default:
      return null;
  }
};
