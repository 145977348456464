import { useEffect, useRef } from "react";
import { isNonNullable } from "@/types/isNonNullable";

export const MAX_TIMEOUT_DELAY = 2_147_483_647;

export const useTimeout = (callback?: () => unknown, delay?: number): void => {
  const savedCallback = useRef<() => unknown>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      if (isNonNullable(savedCallback.current)) {
        savedCallback.current();
      }
    };

    if (isNonNullable(delay)) {
      const id = setTimeout(tick, delay);

      return () => {
        clearTimeout(id);
      };
    }

    return () => {};
  }, [delay]);
};
