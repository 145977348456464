import { useEffect, useRef } from "react";
import { isNonNullable } from "@/types/isNonNullable";

export const useInterval = (
  callback?: () => unknown,
  interval?: number,
): void => {
  const savedCallback = useRef<() => unknown>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      if (isNonNullable(savedCallback.current)) {
        savedCallback.current();
      }
    };

    if (isNonNullable(interval)) {
      const id = setInterval(tick, interval);

      return () => {
        clearInterval(id);
      };
    }

    return () => {};
  }, [interval]);
};
